<template>
  <div class="meet-list">
    <el-breadcrumb class="query-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{dict[language].home}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/meetList'}">{{dict[language].meetSchedule}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="meet-list-warp">
      <div class="meet-list-main">
        <section>
          <div class="meet-list-detail" >
            <div class="meet-list-detail-title">
              {{curObj.title}} {{dict[language].meetingDetails}}
            </div>
            <el-form>
              <el-form-item label="会议名称:">
                {{curObj.title || '-'}}
              </el-form-item>
              <el-form-item label="时间:">
                {{computeTime(curObj) || '-'}}
              </el-form-item>
               <el-form-item label="会议地点:">
                {{curObj.addrName || '-'}}
              </el-form-item>
              <el-form-item label="主办方:">
                {{curObj.sponsor|| '-'}}
              </el-form-item>
              <el-form-item label="会议类型:">
                {{computedMeetType(curObj.type)}}
              </el-form-item>
              <el-form-item label="联系人:">
                {{curObj.contacts}}
              </el-form-item>
              <el-form-item label="联系电话:">
                {{curObj.contactsPhone}}
              </el-form-item>
              <el-form-item label="简介:">
                <div v-if="computedContentIsHtml(curObj.content)">
                  <div v-html="computedContent(curObj.content)"></div>
                </div>
                <div v-else>
                  <ul v-for="imgUrl in computedImages(curObj.content)" :key="imgUrl">
                    <li>
                      <img :src="imgUrl" alt="">
                    </li>
                  </ul>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </section>
      </div>

      <div class="meet-list-right-nav">
        <div>
          <ul>
            <li :class="{active: data.id === curObj.id}" v-for="data in dataList" :key="data.id" @click="handlerClickList(data)">
              <el-tooltip :content="data.title" placement="top-start">
                <span>{{data.title}}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <p class="content-list-more" v-if="total > dataList.length" @click="$router.back()">
          {{dict[this.language].more}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixs from '@/mixs'
import mixsImagesTextParse from '@/mixs/images-text-parse'
import dict from '@/assets/json/dictionaries.json'
import {getSwMeetInfo, getSwMeet} from '@/http/request'
export default {
  name: "MeetInfo",
  props: ['id'],
  inject: ['language'],
  mixins: [mixs, mixsImagesTextParse],
  data() {
    return {
      pageStatus: 'list',
      dataList: [],
      curObj: {},
      dict,
      total: 0,
      dataForm: {
        limit: 10,
        page: 1
      }
    }
  },

  watch: {
    id(_new) {
      if(_new) {
        this.$set(this.dataForm, 'navId', _new)
      }
    },
    "dataForm.navId" () {
      this.resetData();
      this.getSwMeetInfo();
    }
  },

  created() {
    this.$set(this.dataForm, 'language', this.language === 'zh' ? 0 : 1)
    this.getSwMeetInfo()
    this.getSwMeet()
  },

  methods: {
    // 请求会议列表
    getSwMeet(){
      getSwMeet(this.dataForm).then(({data}) => {
        if(data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total;
        }
      })
    },

    //  请求会议内容
    getSwMeetInfo() {
      getSwMeetInfo(this.id).then(({data}) => {
        if(data.code === 0) {
          this.curObj = data.data;
        }
      })
    },

    computeTime({startTime, endTime}) {
      try {
        const st = startTime.replace(/-/g, '.').split(" ")[0];
        const et = endTime.replace(/-/g, '.').split(" ")[0];
        return `${st} - ${et}`
      } catch {
        return ""
      }
    },

    resetData() {
      this.$set(this.dataForm, 'id', this.id);
    },

    // 点击文章列表
    handlerClickList(obj) {
      this.curObj = obj;
    },

    computedMeetType(type) {
      // 0:线上+线下 1:线上 2:线下
      switch(type) {
        case 0:
          return "线上+线下";
        case 1:
          return "线上";
        case 3:
          return "线下";
        default:
          return "-"
      }
    },

    // 组装图片渲染的list
    handleAssemblyList(list) {
      console.log(list)
    }
  }
};
</script>

<style lang='less'>
.meet-list {
  width: 933px;
  margin: 0 auto;
  .query-breadcrumb {
    margin: 25px 0 70px;
  }
  .meet-list-warp {
    display: flex;
    margin-bottom: 50px;
    .meet-list-main {
      flex: 1;
      padding: 0 30px 30px;
      .query-pagination {
        margin: 50px 0;
      }
      
      .meet-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999;
      }
    }
    .meet-list-right-nav {
      width: 130px;
      // padding-left: 45px;
      box-sizing: border-box;
      ul {
        width: 100%;
        li {
          width: 100%;
          padding: 20px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: 400;
          color: #595757;
          line-height: 1.5;
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: rgba(20, 153, 220, 1);
            &::before {
              width: 100%;
              height: 5px;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(20, 153, 220, 1);
            }
          }
          &:first-child {
            border-top: solid 1px #e6e6e6;
          }
        }
      }

      .content-list-more {
        padding: 20px 0 10px;
        text-align: right;
        font-size: 14px;
        color: rgba(20, 153, 220, 1);
        cursor: pointer;
      }
    }
    .el-divider--horizontal {
      margin: 10px 0 50px;
    }
  }
  .meet-list-detail {
    margin-bottom: 50px;
    .meet-list-detail-title {
      margin-bottom: 40px;
      font-size: 31px;
      font-weight: 400;
      color: #2F6D8B;
      line-height: 38px;
    }
    img {
      width: initial;
      max-width: 610px;
      height: auto;
    }
  }
  .el-form-item {
    margin: 0;
  }
}
</style>