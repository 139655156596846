export default {
    computed: {
        computedContentIsHtml() {
            return (content) => {
                try {
                    const cnt = JSON.parse(content);
                    if (typeof cnt === "object" || typeof cnt === "number") {
                        return cnt.type == 2 ? false : true
                    }
                } catch {
                    return true
                }
            }
        },
        computedContent() {
            return (content) => {
                try {
                    const cnt = JSON.parse(content);
                    if (typeof cnt === "object") {
                        return cnt.msg;
                    } else {
                        return cnt
                    }
                } catch {
                    return content
                }
            }
        },
        computedImages() {
            return (content) => {
                const cnt = JSON.parse(content);
                return cnt.msg.split(",")
            }
        }
    }
}